import * as React from 'react';
import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import AttachFile from '@mui/icons-material/AttachFile';
import PropTypes from 'prop-types';

const Label = styled('span')(({ theme }) => ({
  marginLeft: theme.spacing(4)
}));

function InputFile({ id, mimeType, label, onChange }) {
  const [fileName, setFileName] = React.useState('');

  const onFileChange = (event) => {
    const input = event.target;
    const { files } = input;

    setFileName(files[0].name);
    return onChange(event);
  };

  return (
    <label htmlFor={id}>
      <input
        id={id}
        type="file"
        accept={mimeType}
        style={{ display: 'none' }}
        onChange={onFileChange}
      />
      <Button component="span" variant="contained" size="large" startIcon={<AttachFile />}>
        {label}
      </Button>
      <Label>{fileName}</Label>
    </label>
  );
}

InputFile.propTypes = {
  id: PropTypes.string.isRequired,
  mimeType: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

export default InputFile;
