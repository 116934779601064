import * as React from 'react';
import { Backdrop, CircularProgress } from '@mui/material';
import PropTypes from 'prop-types';

function LoadingBackdrop({ open, onClick }) {
  return (
    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={open}
      onClick={onClick}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}

LoadingBackdrop.propTypes = {
  open: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired
};

export default LoadingBackdrop;
