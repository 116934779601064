import * as React from 'react';
import { Alert, Button, Container, Grid, Snackbar, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import axios from 'axios';
import logo from '../images/logo.png';
import LoadingBackdrop from '../components/LoadingBackdrop';
import InputFile from '../components/InputFile';

const Logo = styled('img')(({ theme }) => ({
  width: '250px',
  height: 'auto',
  marginTop: theme.spacing(4)
}));

function App() {
  const [open, setOpen] = React.useState(false);
  const [identifier, setIdentifier] = React.useState('');
  const [name, setName] = React.useState('');
  const [initialSequence, setInitialSequence] = React.useState('');
  const [day, setDay] = React.useState('');
  const [month, setMonth] = React.useState('');
  const [year, setYear] = React.useState('');
  const [wordFile, setWordFile] = React.useState();
  const [excelFile, setExcelFile] = React.useState();
  const [errorAlertOpened, seteErrorAlertOpened] = React.useState(false);

  const currentDate = new Date();

  const handleSubmit = (event) => {
    event.preventDefault();
    setOpen(true);

    const formData = new FormData();
    formData.append('word_template', wordFile);
    formData.append('excel_values', excelFile);
    formData.append('identifier', identifier);
    formData.append('name', name);
    formData.append('initial_sequence', initialSequence);
    formData.append('day', day);
    formData.append('month', month);
    formData.append('year', year);

    // You should have a server side REST API
    axios
      .post(`${process.env.REACT_APP_BACKEND_BASE_URL}/v1/generate_files/`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        responseType: 'blob'
      })
      .then((response) => {
        setOpen(false);

        const filename = response.headers.get('Content-Disposition').split('filename=')[1];
        const blob = response.data;

        const a = document.createElement('a');
        a.href = window.URL.createObjectURL(blob);
        a.download = filename;
        a.click();
      })
      .catch(() => {
        setOpen(false);
        seteErrorAlertOpened(true);
      });
  };

  const onFileChange = (inputName, event) => {
    const input = event.target;
    const { files } = input;

    if (inputName === 'wordFile') {
      setWordFile(files[0]);
    } else if (inputName === 'excelFile') {
      setExcelFile(files[0]);
    }
  };

  const getMonthPlaceholder = (monthNumber) => {
    const monthNames = [
      '01 ou janeiro',
      '02 ou fevereiro',
      '03 ou março',
      '04 ou abril',
      '05 ou maio',
      '06 ou junho',
      '07 ou julho',
      '08 ou agosto',
      '09 ou setembro',
      '10 ou outubro',
      '11 ou novembro',
      '12 ou dezembro'
    ];

    return monthNames[monthNumber];
  };

  return (
    <Container maxWidth="md">
      <LoadingBackdrop
        open={open}
        onClick={() => {
          setOpen(false);
        }}
      />
      <Snackbar
        open={errorAlertOpened}
        autoHideDuration={6000}
        onClose={() => seteErrorAlertOpened(false)}>
        <Alert onClose={() => seteErrorAlertOpened(false)} severity="error" sx={{ width: '100%' }}>
          Ocorreu um error.
        </Alert>
      </Snackbar>
      <form autoComplete="off" onSubmit={handleSubmit}>
        <Grid
          container
          spacing={2}
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start">
          <Grid item xs={12}>
            <Logo src={logo} alt="CRS" />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={4}>
                    <TextField
                      id="sequence-id"
                      fullWidth
                      required
                      label="Início da sequência"
                      placeholder="987"
                      variant="filled"
                      type="number"
                      inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                      helperText="{{ sequence }}"
                      onChange={(event) => {
                        setInitialSequence(event.target.value);
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={4}>
                    <TextField
                      id="lawer-id"
                      fullWidth
                      required
                      label="Identificador do advogado"
                      placeholder="12345A"
                      variant="filled"
                      helperText="{{ identifier }}"
                      onChange={(event) => {
                        setIdentifier(event.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <TextField
                      id="lawer-name"
                      fullWidth
                      required
                      label="Nome do advogado"
                      placeholder="Pedro"
                      variant="filled"
                      helperText="{{ name }}"
                      onChange={(event) => {
                        setName(event.target.value);
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={3}>
                    <TextField
                      id="day"
                      fullWidth
                      required
                      label="Dia"
                      placeholder={currentDate.getDate().toString()}
                      variant="filled"
                      type="number"
                      inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                      helperText="{{ day }}"
                      onChange={(event) => {
                        setDay(event.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <TextField
                      id="month"
                      fullWidth
                      required
                      label="Mês"
                      placeholder={getMonthPlaceholder(currentDate.getMonth().toString())}
                      variant="filled"
                      helperText="{{ month }}"
                      onChange={(event) => {
                        setMonth(event.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <TextField
                      id="year"
                      fullWidth
                      required
                      label="Ano"
                      placeholder={currentDate.getFullYear().toString()}
                      variant="filled"
                      type="number"
                      inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                      helperText="{{ year }}"
                      onChange={(event) => {
                        setYear(event.target.value);
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <InputFile
              id="wordFile"
              mimeType="application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              label="Template Word"
              onChange={(event) => onFileChange('wordFile', event)}
            />
          </Grid>
          <Grid item xs={12}>
            <InputFile
              id="excelFile"
              mimeType="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              label="Excel"
              onChange={(event) => onFileChange('excelFile', event)}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              type="submit"
              variant="text"
              disabled={!(identifier && initialSequence && wordFile && excelFile)}>
              Gerar ficheiro ZIP
            </Button>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
}

export default App;
